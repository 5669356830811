<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <Content :title="$t('navigation.general_settings')">
        <template slot="main">
            <template v-if="loader">
                <v-row class="flex-grow-0">
                    <v-col v-for="tab in tabs" :key="tab.key" class="pa-0">
                        <v-skeleton-loader type="heading" class="d-flex align-center justify-center" height="48" />
                    </v-col>
                </v-row>
                <v-skeleton-loader type="paragraph" class="mt-4" />
            </template>
            <template v-else>
                <v-tabs grow show-arrows v-model="tab">
                    <v-tab v-for="tab in tabs" :key="tab.key">
                        <v-icon top class="mr-2">{{ tab.icon }}</v-icon>
                        {{ tab.title }}
                    </v-tab>

                    <v-tabs-items v-model="tab" class="flex-grow-1">
                        <v-tab-item :key="SettingTabs.GENERAL">
                            <v-container fluid>
                                <v-row>
                                    <v-col cols="12" md="6">
                                        <v-row>
                                            <v-col cols="12" class="pt-0">
                                                <h3 class="font-weight-medium">{{ $t('navigation.general') }}</h3>
                                            </v-col>
                                            <v-col cols="12">
                                                <TextField v-for="(item, index) in generalSettingsTextFields" :key="index + item.id"
                                                           v-model="item.value"
                                                           :readonly="!canUpdate"
                                                           :rules=rules(item)
                                                           :name="`${item.setting_type}`"
                                                           @change="updateList(item.id)"
                                                           :label="$t(`labels.${item.setting_type}`)"></TextField>
                                                <Autocomplete :label="$t('labels.items_per_page')"
                                                              :items="itemsPerPageList"
                                                              name="table_items_per_page"
                                                              @change="updateList(itemsPerPage.id)"
                                                              v-model="itemsPerPage.value"></Autocomplete>
                                                <Checkbox v-for="(item, index) in generalSettingsCheckboxes" :key="index"
                                                          v-model="item.value"
                                                          :readonly="!canUpdate"
                                                          @change="updateList(item.id)"
                                                          class="mt-1"
                                                          false-value="0"
                                                          true-value="1"
                                                          :label="$t(`labels.${item.setting_type}`)"></Checkbox>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col cols="12" md="6">
                                        <v-row>
                                            <v-col cols="12" class="pt-0">
                                                <h3 class="font-weight-medium">{{ $t('labels.identifiers') }}</h3>
                                            </v-col>
                                            <v-col col="12">
                                                <TextField v-for="(item, index) in identifiers" :key="index"
                                                   v-model="item.value"
                                                   :readonly="!canUpdate"
                                                   @change="updateList(item.id)"
                                                   :name="`${item.setting_type}`"
                                                   :label="$t(`labels.${item.setting_type}`)"></TextField>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-tab-item>

                        <v-tab-item :key="SettingTabs.INSPECTIONS">
                            <v-container fluid>
                                <v-row>
                                    <v-col cols="12"><h3 class="font-weight-medium">{{ $t('labels.column_settings')}}</h3></v-col>
                                    <v-col cols="12" md="6" class="py-0" v-for="(item, index) in inspectionsSettings" :key="index">
                                        <Checkbox
                                            v-model="item.value"
                                            :readonly="!canUpdate"
                                            @change="updateList(item.id)"
                                            false-value="0"
                                            true-value="1"
                                            :label="$t(`labels.${item.setting_type}`)"
                                        />
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-tab-item>

                        <v-tab-item :key="SettingTabs.INCIDENTS">
                            <v-container fluid>
                                <v-row>
                                    <v-col cols="12"><h3 class="font-weight-medium">{{ $t('labels.column_settings')}}</h3></v-col>
                                    <v-col cols="12" md="6" class="py-0" v-for="(item, index) in incidentsSettings" :key="index">
                                        <Checkbox
                                            v-model="item.value"
                                            :readonly="!canUpdate"
                                            @change="updateList(item.id)"
                                            false-value="0"
                                            true-value="1"
                                            :label="$t(`labels.${item.setting_type}`)"
                                        />
                                    </v-col>
                                </v-row>
                            </v-container>>
                        </v-tab-item>

                        <v-tab-item :key="SettingTabs.REPORTS">
                            <v-container fluid>
                                <v-row>
                                    <v-col cols="12"><h3 class="font-weight-medium">{{ $t('labels.reports')}}</h3></v-col>
                                    <v-col cols="12" md="6" v-for="(item, index) in reportSettingsTextFields" :key="index + item.id">
                                        <TextField
                                           v-model="item.value"
                                           :readonly="!canUpdate"
                                           :name="`${item.setting_type}`"
                                           @change="updateList(item.id)"
                                           :label="$t(`labels.${item.setting_type}`)"></TextField>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-tab-item>
                    </v-tabs-items>
                </v-tabs>
                <v-btn v-if="canUpdate"
                       color="secondary"
                       @click="save()"
                       :loading="loader"
                       :disabled="loader || dataToUpdate.length === 0"
                       class="flex-grow-0 mt-3"
                >
                    <v-icon left>
                        {{ ICONS.SAVE}}
                    </v-icon>
                    {{ $t('buttons.save') }}
                </v-btn>
            </template>
        </template>
    </Content>
</template>

<script>
import { mapFields } from 'vuex-map-fields';
import TextField from '@/components/widgets/forms/TextField';
import { ICONS } from '@/constants/icons';
import systemSettingsApi from '@/utils/api/settings';
import Checkbox from '@/components/widgets/forms/Checkbox';
import Autocomplete from '@/components/widgets/forms/Autocomplete';
import Content from '@/components/templates/Content.vue';

export default {
    name: 'ConfigGlobal',
    components: { Content, Autocomplete, TextField, Checkbox },

    data: () => ({
        loader: false,
        updatedIds: [],
        ICONS,
        generalSettingsCheckboxesConfig: ['INC_COMMENTS', 'GPS_SHOW_STATUS', 'HIDE_TIMESTAMP'],
        generalSettingsInputConfig: ['NUMBER_PER_PAGE'],
        tab: null,
        SettingTabs: {
            GENERAL: 'general',
            INSPECTIONS: 'inspections',
            INCIDENTS: 'incidents',
            REPORTS: 'reports'
        }
    }),
    computed: {
        ...mapFields('systemSettings', [
            'settings'
        ]),
        generalSettingsTextFields () {
            return this.sortInputs(this.settings.filter(e => e.setting_type === 'ADMIN_EMAIL' ||
                e.setting_type === 'APP_GPS_DISTANCE'));
        },
        generalSettingsCheckboxes () {
            return this.sortInputs(this.settings.filter(e => this.generalSettingsCheckboxesConfig.includes(e.setting_type)));
        },
        itemsPerPage: {
            get () {
                const val = this.settings.find(e => e.setting_type === 'NUMBER_PER_PAGE');
                if (val) {
                    val.value = parseInt(val.value);
                    return val;
                }
                return {
                    ...val,
                    value: val?.value || null
                };
            },
            set (val) {
                this.settings.find(e => e.setting_type === 'NUMBER_PER_PAGE').value = val;
            }
        },
        inspectionsSettings () {
            return this.sortInputs(this.settings.filter(e => e.group === 'General_settings_inspection'));
        },
        incidentsSettings () {
            return this.sortInputs(this.settings.filter(e => e.group === 'General_settings_incident'));
        },
        reportSettingsTextFields () {
            return this.sortInputs(this.settings.filter(e => e.group === 'Reports'));
        },
        identifiers () {
            return this.sortInputs(this.settings.filter(e => e.group === 'Identifier'));
        },
        itemsPerPageList () {
            return [
                { text: '20', value: 20 },
                { text: '50', value: 50 },
                { text: '100', value: 100 }
            ];
        },
        canUpdate () {
            return this.$can(this.$abilityActions.UPDATE, this.$abilitySubjects.SYSTEM_SETTINGS);
        },
        tabs () {
            return [
                {
                    key: this.SettingTabs.GENERAL,
                    title: this.$t('navigation.general'),
                    icon: ICONS.SETTINGS
                },
                {
                    key: this.SettingTabs.INSPECTIONS,
                    title: this.$t('navigation.inspections'),
                    icon: ICONS.INSPECTIONS
                },
                {
                    key: this.SettingTabs.INCIDENTS,
                    title: this.$t('navigation.incidents'),
                    icon: ICONS.INCIDENTS
                },
                {
                    key: this.SettingTabs.REPORTS,
                    title: this.$t('navigation.reports'),
                    icon: ICONS.REPORTS
                }
            ];
        },
        dataToUpdate () {
            return this.settings.filter(el => this.updatedIds.includes(el.id)).map(item => this.updateModel(item));
        }
    },
    methods: {
        sortInputs (arr) {
            return arr.sort((a, b) => {
                return a.setting_type > b.setting_type;
            });
        },
        rules (item) {
            if (item && item.setting_type === 'mailAddress') {
                return { max: 40, email: true };
            } else return {};
        },
        updateList (id) {
            if (this.updatedIds.indexOf(id) === -1) {
                this.updatedIds.push(id);
            }
        },
        updateModel (item) {
            if (item) {
                let val = item.value;
                if (item.value === false) val = '0';
                if (item.value === true) val = '1';
                return {
                    id: item.id,
                    type: 'system_settings',
                    attributes: {
                        setting_type: item.setting_type,
                        value: val
                    }
                };
            }
        },
        async save () {
            if (this.dataToUpdate.length > 0 && this.canUpdate) {
                return await systemSettingsApi.updateSettings(this.dataToUpdate).finally(() => {
                    this.fetch();
                    this.updatedIds = [];
                });
            }
        },
        fetch () {
            this.loader = true;
            this.$store.dispatch('systemSettings/getAllSettings')
                .finally(() => {
                    this.loader = false;
                });
        }
    },
    beforeRouteEnter (to, from, next) {
        next(vm => {
            if (vm.$can(vm.$abilityActions.READ, vm.$abilitySubjects.SYSTEM_SETTINGS)) {
                vm.fetch();
            } else {
                vm.$router.push({ name: 'home' });
            }
        });
    }
};
</script>

<style scoped lang="scss">
    ::v-deep {
        & .v-tabs {
            display: contents;
        }
        & .v-tabs-bar {
            flex-grow: 0;
            min-height: 48px;
        }
        & .v-tabs-items {
            height: 100%;
            overflow: hidden;

            > div {
                overflow-x: hidden;
                overflow-y: auto;
            }
        }
    }
</style>
